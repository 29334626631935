<template>
  <div>
    <k-crud-page>
      <template #resultlist>
        <k-crud-table
          :headers="columns"
          :items="cruddataMX.dataList"
          :total="cruddataMX.pagination.total"
          :value="cruddataMX.pagination.current"
          @pageChanged="
            (page) => {
              crudPaginationMX().change(page);
            }
          "
        >
          <template #createdTime="{ item }">
            {{ oprMessage(item.items, 1) }}
          </template>
          <template #roleVos="{ item }">
            {{ oprMessage(item.roles, 2) }}
          </template>
          <template #roleVos1="{ item }">
            {{ oprMessage(item.targets, 2) }}
          </template>
          <template #top>
            <v-toolbar flat>
              <k-primary-button
                icon="mdi-plus"
                text="新增消息配置"
                @click="
                  getDetail();
                  crudActionMX().newItem(onOpenEdit());
                "
              />
              <k-crud-dialog
                v-model="crud.switch"
                :title="crud.title"
                @save="
                  () => {
                    crudActionMX().saveItem();
                  }
                "
                @close="
                  () => {
                    crudActionMX().closeEditingPage(crud);
                  }
                "
              >
                <template #dialogContent>
                  <v-form :ref="crud.form.ref">
                    <div class="d-flex flex-column">
                      <k-select
                        v-model="moduleUuid"
                        :items="moudleList"
                        label="业务模块"
                      >
                      </k-select>
                      <k-select
                        v-model="items"
                        :items="crud.select.moudle.messageList"
                        label="消息"
                        :disabled="lodash.isEmpty(moduleUuid)"
                        multiple
                        chips
                        class="my-2"
                      />
                      <div class="d-flex flex-wrap">
                        <div class="ml-10 mt-5">推送对象:</div>
                        <div
                          v-for="(item, index) in crud.select.moudle.roles"
                          :key="index"
                          class="pl-1"
                        >
                          <v-checkbox
                            v-model="item.checked"
                            :label="item.name"
                          ></v-checkbox>
                        </div>
                      </div>
                      <div class="d-flex flex-wrap">
                        <div class="ml-10 mt-5">推送目标:</div>
                        <div
                          v-for="(item, index) in crud.select.moudle.targets"
                          :key="index"
                          class="pl-1"
                        >
                          <v-checkbox
                            v-model="item.checked"
                            :label="item.name"
                          ></v-checkbox>
                        </div>
                      </div>
                    </div>
                  </v-form>
                </template>
              </k-crud-dialog>
            </v-toolbar>
          </template>
          <template #actions="{ item }">
            <v-icon
              class="mr-2"
              @click="crudActionMX().editItem(item, onOpenEdit(item))"
            >
              mdi-pencil
            </v-icon>
            <v-icon @click="crudActionMX().deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </k-crud-table>
      </template>
    </k-crud-page>
  </div>
</template>
<script>
import lodash from 'lodash';
import messagePushEnums from '@/enums/messagePush/messagePushEnum';

export default {
  data() {
    return {
      lodash,
      messagePushEnums,
      testvalue: '',
      crud: {
        name: '消息配置',
        title: '', // 在Mixin中被修改
        switch: false,
        uploading: false,
        loading: false,
        form: {
          ref: 'accountModalForm',
        },
        select: {
          moudle: {
            loading: false,
            current: 1,
            defaultPageSize: 10,
            roles: [],
            targets: [],
            messageList: [],
          },
        },
      },
      columns: [
        { text: '序号', value: 'indexNum', sortable: false, algin: 'center' },
        {
          text: '业务模块',
          value: 'moduleName',
          sortable: false,
          algin: 'center',
        },
        {
          text: '消息',
          value: 'createdTime',
          algin: 'center',
          sortable: false,
        },
        {
          text: '推送对象',
          value: 'roleVos',
          algin: 'center',
          sortable: false,
        },
        {
          text: '推送目标',
          value: 'roleVos1',
          algin: 'center',
          sortable: false,
        },
        { text: '操作', value: 'actions', sortable: false, algin: 'center' },
      ],
      rules: {
        required: (value) => !!value || '不能为空',
      },
      roles: [],
      targets: [],
      moudleList: [],
      moduleUuid: '',
      items: [],
    };
  },
  computed: {},
  watch: {
    moduleUuid() {
      this.getAnalysisItems(this.moduleUuid);
    },
  },
  created() {
    this.getMoudle();
    this.crudInitMX(
      this.crud,
      {
        list: this.$api.messagePush.message.getMPC,
        add: (params) => {
          params.message = this.items;
          params.moduleUuid = this.moduleUuid;
          params.roles = this.crud.select.moudle.roles;
          params.targets = this.crud.select.moudle.targets;
          return this.$api.messagePush.message.addMPC(params);
        },
        edit: (params) => {
          params.message = this.items;
          params.moduleUuid = this.moduleUuid;
          params.roles = this.crud.select.moudle.roles;
          params.targets = this.crud.select.moudle.targets;
          return this.$api.messagePush.message.editMPC(params);
        },
        delete: this.$api.messagePush.message.deleteMPC,
      },
      {},
      {
        add: ['moduleUuid', 'uuid', 'targets', 'items', 'roles'],
        edit: ['moduleUuid', 'uuid', 'targets', 'items', 'roles'],
      }
    );
    this.getDetail();
  },
  methods: {
    onOpenEdit(item) {
      if (item === undefined) {
        this.items = [];
        this.moduleUuid = '';
      } else {
        this.items = [];
        this.moduleUuid = item.moduleUuid;
        this.getAnalysisItems(this.moduleUuid);
        if (item.roles !== null && item.roles !== undefined) {
          for (let index = 0; index < item.roles.length; index++) {
            if (item.roles[index].checked) {
              this.crud.select.moudle.roles[index].checked = true;
            } else {
              this.crud.select.moudle.roles[index].checked = false;
            }
          }
        }
        if (item.targets !== null && item.targets !== undefined) {
          for (let index = 0; index < item.targets.length; index++) {
            if (item.targets[index].checked) {
              this.crud.select.moudle.targets[index].checked = true;
            } else {
              this.crud.select.moudle.targets[index].checked = false;
            }
          }
        }
        if (item.items !== null) {
          for (let index = 0; index < item.items.length; index++) {
            const element = item.items[index];
            this.items.push(element.uuid);
          }
        }
      }
    },
    // 数据处理
    oprMessage(item, flg) {
      let tempList = [];
      if (flg === 1) {
        if (item !== null) {
          for (let index = 0; index < item.length; index++) {
            tempList.push(item[index].itemName);
          }
        }
        return tempList.join(',');
      } else if (flg === 2) {
        if (item !== null) {
          for (let index = 0; index < item.length; index++) {
            if (item[index].checked === true) {
              tempList.push(item[index].name);
            }
          }
        }
        return tempList.join(',');
      }
    },
    // 消息
    getAnalysisItems(uuid) {
      this.$api.messagePush.message
        .getAnalysisItems({
          moduleUuid: uuid,
        })
        .then((res) => {
          this.crud.select.moudle.messageList = res.data;
        });
    },
    // 初期下拉框值获取
    getMoudle() {
      this.$api.messagePush.message.getModule().then((res) => {
        this.moudleList = res.data;
      });
    },
    // 初期设置复选框组值
    getDetail() {
      this.$api.messagePush.message.getDetail().then((res) => {
        this.crud.select.moudle.roles = res.data[0].roles;
        this.crud.select.moudle.targets = res.data[0].targets;
      });
    },
  },
};
</script>
