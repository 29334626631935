/* eslint-disable prettier/prettier */
import Enum from '@/enums/enums';

/**
 * @author ljw
 */
export default new Enum()
  .add('APP', 'APP', '01')
  .add('MINIPROGRAM', '小程序', '02')
  .add('SMS', '短信', '03');
