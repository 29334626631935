var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('k-crud-page',{scopedSlots:_vm._u([{key:"resultlist",fn:function(){return [_c('k-crud-table',{attrs:{"headers":_vm.columns,"items":_vm.cruddataMX.dataList,"total":_vm.cruddataMX.pagination.total,"value":_vm.cruddataMX.pagination.current},on:{"pageChanged":function (page) {
            _vm.crudPaginationMX().change(page);
          }},scopedSlots:_vm._u([{key:"createdTime",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.oprMessage(item.items, 1))+" ")]}},{key:"roleVos",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.oprMessage(item.roles, 2))+" ")]}},{key:"roleVos1",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.oprMessage(item.targets, 2))+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('k-primary-button',{attrs:{"icon":"mdi-plus","text":"新增消息配置"},on:{"click":function($event){_vm.getDetail();
                _vm.crudActionMX().newItem(_vm.onOpenEdit());}}}),_c('k-crud-dialog',{attrs:{"title":_vm.crud.title},on:{"save":function () {
                  _vm.crudActionMX().saveItem();
                },"close":function () {
                  _vm.crudActionMX().closeEditingPage(_vm.crud);
                }},scopedSlots:_vm._u([{key:"dialogContent",fn:function(){return [_c('v-form',{ref:_vm.crud.form.ref},[_c('div',{staticClass:"d-flex flex-column"},[_c('k-select',{attrs:{"items":_vm.moudleList,"label":"业务模块"},model:{value:(_vm.moduleUuid),callback:function ($$v) {_vm.moduleUuid=$$v},expression:"moduleUuid"}}),_c('k-select',{staticClass:"my-2",attrs:{"items":_vm.crud.select.moudle.messageList,"label":"消息","disabled":_vm.lodash.isEmpty(_vm.moduleUuid),"multiple":"","chips":""},model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}}),_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"ml-10 mt-5"},[_vm._v("推送对象:")]),_vm._l((_vm.crud.select.moudle.roles),function(item,index){return _c('div',{key:index,staticClass:"pl-1"},[_c('v-checkbox',{attrs:{"label":item.name},model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}})],1)})],2),_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"ml-10 mt-5"},[_vm._v("推送目标:")]),_vm._l((_vm.crud.select.moudle.targets),function(item,index){return _c('div',{key:index,staticClass:"pl-1"},[_c('v-checkbox',{attrs:{"label":item.name},model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}})],1)})],2)],1)])]},proxy:true}]),model:{value:(_vm.crud.switch),callback:function ($$v) {_vm.$set(_vm.crud, "switch", $$v)},expression:"crud.switch"}})],1)]},proxy:true},{key:"actions",fn:function(ref){
                var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){_vm.crudActionMX().editItem(item, _vm.onOpenEdit(item))}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{on:{"click":function($event){_vm.crudActionMX().deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }